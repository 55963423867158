import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import { useState } from 'react';

function App() {
    const [htmlContent,setHTMLContent] = useState(null);
    const pay=async ()=>{
        const response = await axios.get("https://569b-103-40-73-165.ngrok-free.app/about",{
            headers: { "ngrok-skip-browser-warning": "true","Accept": "text/html" }
          });
        console.log(response);
        setHTMLContent(response.data);
    }

    return (
        <div className="App">
            {htmlContent && <div><div dangerouslySetInnerHTML={{ __html: htmlContent }} /><button style={{margin:"2%"}} onClick={()=>setHTMLContent(null)}>Cancel Payment</button></div>}
            {!htmlContent && <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
        
                <button onClick={pay} style={{marginTop:"2%",padding:"0.5%", cursor:"pointer", borderRadius:"3%"}}>Start Payment</button>
            </header>}
        </div>
    );
}

export default App;
